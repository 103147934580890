import { useLingui } from '@lingui/react'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUser, makeUserFavorite, removeUser, selectFavoritesForSync, setSyncData, unsetUsers } from '../redux/favorites'
import { t } from '@lingui/macro'
import { useHistory, useLocation } from 'react-router-dom'
import gql from 'graphql-tag.macro'
import { useLazyQuery, useMutation } from '@apollo/client'
import firebase from '../firebase'

export default function useFavorites(userId) {

    const dispatch = useDispatch()

    const selectUserFavorite = React.useMemo(() => makeUserFavorite(), [])

    const favorite = useSelector(state => selectUserFavorite(state, userId))

    const inList = favorite ? true : false;

    let triggerAction;

    if (favorite) {
        triggerAction = 'edit'
    } else {
        triggerAction = 'add'
    }

    const { enqueueSnackbar } = useSnackbar()

    const { i18n } = useLingui()

    const history = useHistory()

    const location = useLocation()

    const trigger = React.useCallback(() => {
        switch (triggerAction) {
            case 'add':
                dispatch(addUser(userId))
                history.push({
                    pathname: `/favorites/edit/${userId}`,
                    state: {
                        background: location
                    }
                })
                break;
            case 'edit':
                history.push({
                    pathname: `/favorites/edit/${userId}`,
                    state: {
                        background: location
                    }
                })
                break
            case 'remove':
                dispatch(removeUser(userId))
                enqueueSnackbar(i18n._(t`Обявата е премахната от списъка ви с любими`), {
                    autoHideDuration: 1200
                })
                break
            default:
                return;
        }
    }, [userId, triggerAction, dispatch, history, location, enqueueSnackbar, i18n])

    return React.useMemo(() => ({
        trigger,
        inList
    }), [trigger, inList])
}

const SYNC_MUTATION = gql`
mutation syncUserFavorites($input:[UserFavoriteInput!]!) {
    syncUserFavorites(input:$input) {
        favoriteId
        note
        lists
    }
}
`

const GET_FAVORITES = gql`
query getUserFavorites {
    getUserFavorites {
        favoriteId
        note
        lists
    }
}
`

export function useFavoritesSync() {

    const dispatch = useDispatch()
    const logged = useSelector(state => state.user.user ? true : false)
    const favoriteUsersForSync = useSelector(selectFavoritesForSync)

    const [sync] = useMutation(SYNC_MUTATION)
    const [getUsers, { data: getUsersData }] = useLazyQuery(GET_FAVORITES)

    React.useEffect(() => {
        if (logged) {
            firebase.auth().currentUser.getIdToken().then(token => {
                getUsers({
                    context: {
                        headers: {
                            authorization: `bearer ${token}`
                        }
                    }
                })
            })
        }
    }, [logged, getUsers])

    React.useEffect(() => {
        if (getUsersData) {
            dispatch(setSyncData(getUsersData.getUserFavorites))
        }
    }, [getUsersData, dispatch])

    React.useEffect(() => {

        if (!logged && favoriteUsersForSync.length > 0) {
            dispatch(unsetUsers(favoriteUsersForSync))
        }

        if (logged && favoriteUsersForSync.length > 0) {
            const timer = setTimeout(() => {
                dispatch(unsetUsers(favoriteUsersForSync))
                firebase.auth().currentUser.getIdToken().then(token => {
                    const context = {
                        headers: {
                            authorization: `bearer ${token}`
                        }
                    }
                    sync({
                        variables: {
                            input: favoriteUsersForSync
                        },
                        context
                    }).then(({ data }) => {
                        dispatch(setSyncData(data.syncUserFavorites))
                    })
                })

            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [logged, favoriteUsersForSync, dispatch, sync, getUsers])
}