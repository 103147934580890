import { createSlice } from '@reduxjs/toolkit'

const commonReducer = (action, getNext) => ({
    prepare(objectId) {
        return {
            payload: {
                action,
                objectId,
                next: getNext().toISOString()
            }
        }
    },
    reducer(state, { payload: { objectId, action, next } }) {
        const found = state.actions.find(item => item.action === action && item.objectId === objectId)
        if (!found || (found && new Date() > new Date(found.next))) {
            if (found) {
                state.actions.splice(state.actions.indexOf(found), 1)
            }
            state.actions.push({
                objectId,
                action,
                next
            })
            state.send.push({
                objectId,
                action
            })
        }
    }
})

function getNext(seconds) {
    return () => new Date(new Date().getTime() + 1000 * seconds)
}

const { reducer, actions } = createSlice({
    name: 'collect',
    initialState: {
        actions: [],
        send: []
    },
    reducers: {
        reset(state) {
            state.send = []
        },
        ClassifiedListView: commonReducer('ClassifiedListView', getNext(60 * 5)),
        ClassifiedView: commonReducer('ClassifiedView', getNext(3600 * 24)),
        ClassifiedShare: commonReducer('ClassifiedShare', getNext(3600 * 24)),
        ClassifiedPhoneView: commonReducer('ClassifiedPhoneView', getNext(3600 * 24)),
    }
})

export default reducer

export const {
    reset,
    ClassifiedListView,
    ClassifiedView,
    ClassifiedShare,
    ClassifiedPhoneView
} = actions