import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import store from './redux/store'
import { SnackbarProvider } from 'notistack'
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react'

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities';

import './App.css'
import "fontsource-roboto/cyrillic-300.css"
import "fontsource-roboto/cyrillic-400.css"
import "fontsource-roboto/cyrillic-500.css"
import "fontsource-roboto/cyrillic-700.css"

import Logo from './component/svg/Logo'
import { activateLanguage, getLocale } from './i18n'
import { dark } from './theme'
import Updates from './component/Updates'
import { Helmet } from 'react-helmet'
import { LazyLoader } from './component/LazyLoader';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getClassifieds: offsetLimitPagination(["services", "places", "languages", "haircolor", "eyes", "age", "price", "weight", "height", "cityName", "byUsers", "nearby", "sort"])
        }
      }
    }
  })
})

const Pages = LazyLoader(() => import('./page'), <div className="initial-loader"><Logo /><div className="title">Nejnost.net</div></div>)

function App() {

  React.useEffect(() => {
    activateLanguage(getLocale(), false)
      .catch(err => console.log(`Cant load default locale: ${err.message}`))
  }, [])

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <I18nProvider i18n={i18n}>
          <Helmet titleTemplate="Nejnost.net - %s">
            <title>Нежни ласки за всеки джоб</title>
          </Helmet>
          <ThemeProvider theme={dark}>
            <CssBaseline />
            <SnackbarProvider>
              <Updates />
              <Pages />
            </SnackbarProvider>
          </ThemeProvider>
        </I18nProvider>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
