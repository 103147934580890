import { createSlice } from '@reduxjs/toolkit'

const { reducer, actions } = createSlice({
    name: 'user',
    initialState: {
        loading: true,
        user: null
    },
    reducers: {
        setUser: {
            prepare(currentUser) {
                return {
                    payload: currentUser ? {
                        uid: currentUser.uid,
                        phoneNumber: currentUser.phoneNumber
                    } : null
                }
            },
            reducer(state, { payload }) {
                state.loading = false
                state.user = payload
            }
        }
    }
})

export default reducer

export const { setUser } = actions