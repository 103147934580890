import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Button } from '@material-ui/core'
import firebase from '../firebase'

export function useAppUpdate() {

    const hasUpdates = useSelector(state => state.app.hasUpdates)

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (hasUpdates) {

            firebase.analytics().logEvent('app', {
                label: 'pwa-need-update'    
            })

            enqueueSnackbar(<Trans>Nejnost.net има нова версия, моля актуализирайте.</Trans>, {
                key: 'appUpdate',
                preventDuplicate: true,
                persist: true,
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'top'
                },
                action: <Button size="small" color="secondary" onClick={() => {
                    firebase.analytics().logEvent('app', {
                        label: 'pwa-update'    
                    })
                    window.location.reload()
                }}><Trans>Актуализация</Trans></Button>
            })
        }
    }, [hasUpdates, enqueueSnackbar])
}