import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export const DEFAULT_ID = 'default'

export const SYNC_STATE = {
    SYNC: 'SYNC',
    NOT_SYNC: 'NOT_SYNC',
    LOADING: 'LOADING'
}

const initialState = {
    lists: [{
        id: 'default',
        totalUsers: 0
    }],
    users: {},
    notes: {},
    userLists: {}
}

const { actions, reducer } = createSlice({
    name: 'favorites',
    initialState,
    reducers: {
        addUser: {
            prepare(userId, listId) {
                return {
                    payload: {
                        userId: String(userId),
                        listId: listId || DEFAULT_ID
                    }
                }
            },
            reducer(state, { payload: { userId, listId } }) {
                if (!state.users[userId]) {
                    state.users[userId] = {}
                }
                if (!state.notes[userId]) {
                    state.notes[userId] = ''
                }
                if (!state.userLists[userId]) {
                    state.userLists[userId] = {}
                }
                state.users[userId].sync = SYNC_STATE.NOT_SYNC
                state.users[userId].removed = false
                if (!(listId in state.userLists[userId])) {
                    state.userLists[userId][listId] = (new Date()).toISOString()
                    for (const list of state.lists) {
                        if (list.id === listId) {
                            list.totalUsers++;
                        }
                    }
                }
            }
        },
        removeUser: {
            prepare(userId, listId) {
                return {
                    payload: {
                        userId: String(userId),
                        listId
                    }
                }
            },
            reducer(state, { payload: { userId, listId } }) {
                if (userId in state.users) {
                    let removed;
                    if (listId) {
                        if (listId in state.userLists[userId]) {
                            delete state.userLists[userId][listId]
                            for (const list of state.lists) {
                                if (list.id === listId) {
                                    list.totalUsers--;
                                }
                            }
                            if (!Object.keys(state.userLists[userId]).length) {
                                removed = true
                            }
                        }
                    } else {
                        for (const listId in state.userLists[userId]) {
                            for (const list of state.lists) {
                                if (list.id === listId) {
                                    list.totalUsers--;
                                }
                            }
                        }
                        removed = true
                    }

                    state.users[userId].sync = SYNC_STATE.NOT_SYNC

                    if (removed) {
                        state.users[userId].removed = true
                        delete state.userLists[userId]
                        delete state.notes[userId]
                    }
                }
            }
        },
        unsetUsers: {
            prepare(usersToSync) {
                const unset = []
                for (const item of usersToSync) {
                    if (!item.lists.length) {
                        unset.push(item.userId)
                    }
                }
                return {
                    payload: unset
                }
            },
            reducer(state, { payload }) {
                for (const userId of payload) {
                    delete state.users[userId]
                }
            }
        },
        updateNote: {
            prepare(userId, note) {
                return {
                    payload: { userId, note }
                }
            },
            reducer(state, { payload: { userId, note } }) {
                state.users[userId].sync = SYNC_STATE.NOT_SYNC
                state.notes[userId] = note
            }
        },
        addList: {
            prepare(name) {
                return {
                    payload: {
                        id: name,
                        name
                    }
                }
            },
            reducer(state, { payload: { id, name } }) {
                if (!state.lists.find(list => list.name === name)) {
                    state.lists.push({
                        id,
                        name,
                        totalUsers: 0
                    })
                }

            }
        },
        setSyncData: {
            reducer(state, { payload }) {
                for (let { favoriteId, lists, note } of payload) {
                    if (!state.users[favoriteId]) {
                        state.users[favoriteId] = {}
                    }
                    state.users[favoriteId].sync = SYNC_STATE.SYNC
                    state.users[favoriteId].removed = false
                    state.notes[favoriteId] = note
                    state.userLists[favoriteId] = {}
                    if (!lists.length) {
                        state.userLists[favoriteId][DEFAULT_ID] = true
                    } else {
                        for (const listId of lists) {
                            state.userLists[favoriteId][listId] = true
                        }
                    }
                }

                const allLists = {}
                for (const favId in state.userLists) {
                    for (const listId in state.userLists[favId]) {
                        if(!state.lists.find(l=>l.id === listId)) {
                            state.lists.push({
                                id: listId,
                                name: listId,
                                totalUsers: 0
                            })
                        }
                        if(!allLists[listId]) {
                            allLists[listId] = 0
                        }
                        allLists[listId]++
                    }
                }

                for (const list of state.lists) {
                    list.totalUsers = allLists[list.id] || 0
                }
            }
        },
        reset(state) {
            for(const key in initialState) {
                state[key] = initialState[key]
            }
        }
    }
})

export default reducer

export const { addUser, removeUser, unsetUsers, addList, updateNote, setSyncData, reset } = actions

export function makeUserFavorite() {
    return createSelector(
        state => state.favorites.users,
        state => state.favorites.userLists,
        (_, userId) => String(userId),
        (users, userLists, userId) => {
            if (users[userId] && !users[userId].removed) {
                return {
                    createdAt: users[userId],
                    lists: Object.keys(userLists[userId])
                }
            }
            return false
        }
    )
}

export const selectUsersFromList = createSelector(
    state => state.favorites.userLists,
    (_, listId) => listId,
    (userLists, listId) => {
        const users = Object.keys(userLists);
        if (listId) {
            return users.filter(userId => {
                return listId in userLists[userId]
            })
        }
        return users
    }
)

export const selectSortedList = createSelector(
    state => state.favorites.lists,
    (list) => {
        return list.concat().sort((a, b) => a.totalUsers > b.totalUsers ? -1 : 1)
    }
)

export const selectFavoritesForSync = createSelector(
    state => state.favorites.users,
    state => state.favorites.userLists,
    state => state.favorites.notes,
    (users, lists, notes) => {
        const list = []
        for (const userId in users) {
            if (users[userId].sync !== SYNC_STATE.NOT_SYNC) {
                continue
            }
            list.push({
                userId,
                lists: userId in lists ? Object.keys(lists[userId]) : [],
                note: userId in notes ? notes[userId] : ''
            })
        }
        return list
    }
)