import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import gql from 'graphql-tag.macro'
import { useMutation } from '@apollo/client'
import firebase from '../firebase'
import { get as getCookie } from 'js-cookie'
import {
    reset,
    ClassifiedListView,
    ClassifiedView,
    ClassifiedPhoneView,
    ClassifiedShare
} from '../redux/collect'

const CollectQuery = gql`
mutation collectClientActions($clientId:String!, $actions:[ClientActionInput!]!) {
    collectClientActions(clientId:$clientId, actions:$actions)
}
`

function useCollectAction(id, action) {

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (id) {
            dispatch(action(id))
        }
    }, [id, dispatch, action])
}

export function useCollectSend() {

    const dispatch = useDispatch()

    const actions = useSelector(state => state.collect.send)

    const [mutate] = useMutation(CollectQuery)

    React.useEffect(() => {
        if (actions.length) {
            const timer = setTimeout(async () => {
                dispatch(reset(actions))
                const authToken = await firebase.auth().currentUser?.getIdToken()
                mutate({
                    context: {
                        headers: authToken ? {
                            authorization: `bearer ${authToken}`
                        } : null
                    },
                    variables: {
                        clientId: getCookie('_ga') || 'n/a',
                        actions: actions.map(({ action, objectId }) => ({
                            action,
                            objectId
                        }))
                    }
                }).catch(err => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(err)
                    }
                })
            }, 1600)
            return () => clearTimeout(timer)
        }
    }, [dispatch, actions, mutate])
}

export function useCollectClassifiedListView(id) {
    const dispatch = useDispatch()
    return React.useCallback(() => dispatch(ClassifiedListView(id)), [id, dispatch])
}

export function useCollectClassifiedView(id) {
    useCollectAction(id, ClassifiedView)
}

export function useCollectClassifiedShare(id) {
    useCollectAction(id, ClassifiedShare)
}

export function useCollectClassifiedPhone(id) {
    useCollectAction(id, ClassifiedPhoneView)
}