import { i18n } from '@lingui/core';
import { en, bg } from 'make-plural/plurals'
import { detect, fromPath as _fromPath, fromStorage } from "@lingui/detect-locale"

export const locales = {
    bg: {
        lang: 'bg-BG',
        text: "Български"
    },
    en: {
        lang: 'en-US',
        text: "English"
    }
};

export const defaultLocale = "bg";

i18n.loadLocaleData({
    bg: { plurals: bg },
    en: { plurals: en }
})

const DefaultKey = 'locale'

export function fromPath() {
    return _fromPath(0)
}

export function getLocale() {
    const locale = detect(
        fromStorage(DefaultKey),
        fromPath(),
        () => defaultLocale
    ).split('-')[0].toLowerCase()
    return locale in locales ? locale : defaultLocale
}

export async function activateLanguage(locale, saveToStorage = true) {
    const { messages } = await import(`./locales/${locale}/messages`)
    i18n.load(locale, messages)
    i18n.activate(locale)
    if (saveToStorage) {
        window.localStorage.setItem(DefaultKey, locale)
    }
}