import React from 'react'

export function LazyLoader(lazy, Loader) {
    const Cmp = React.lazy(lazy)
    return props => (
        <React.Suspense fallback={Loader}>
            <Cmp {...props} />
        </React.Suspense>
    )
}
