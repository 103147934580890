import { createSlice } from "@reduxjs/toolkit"

let pwaInstallPrompt;

const { reducer, actions } = createSlice({
    name: 'app',
    initialState: {
        pwaPrompt: null,
        pwaInstall: false,
        hasUpdates: false
    },
    reducers: {
        setPwaPrompt: {
            prepare(prompt) {
                pwaInstallPrompt = prompt
                return { payload: Boolean(prompt) }
            },
            reducer(state, { payload }) {
                state.pwaPrompt = payload
            }
        },
        setPwaInstall: {
            prepare(value) {
                return { payload: Boolean(value) }
            },
            reducer(state, { payload }) {
                state.pwaInstall = payload
            }
        },
        setHasUpdates: {
            prepare(bool) {
                return { payload: Boolean(bool) }
            },
            reducer(state, { payload }) {
                state.hasUpdates = payload
            }
        }
    }
})

export default reducer

export const { setPwaPrompt, setPwaInstall, setHasUpdates } = actions

export const showPwaPrompt = () => dispatch => {
    pwaInstallPrompt.prompt()
    pwaInstallPrompt.userChoice.then(() => {
        dispatch(setPwaPrompt(null))
    });
}