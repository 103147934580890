import { createSelector, createSlice } from '@reduxjs/toolkit'

const { reducer, actions } = createSlice({
    name: 'location',
    initialState: {
        coords: null,
        timestamp: null,
        disabled: false
    },
    reducers: {
        setPosition: {
            prepare(position) {
                return {
                    payload: {
                        timestamp: position.timestamp,
                        coords: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    }
                }
            },
            reducer(state, { payload }) {
                state.disabled = false
                state.timestamp = payload.timestamp
                state.coords = payload.coords
            }
        },
        disable(state) {
            state.disabled = (new Date()).getTime()
        }
    }
})

export default reducer

export const { setPosition, disable } = actions

export const LocationAsk = {
    FirstTime: 'FirstTime',
    Update: 'Update'
}

export const selectAskForLocation = createSelector(
    state => state.location.disabled ? true : false,
    state => state.location.timestamp,
    (disabled, lastUpdate) => {
        if (!disabled) {
            if (!lastUpdate) {
                return LocationAsk.FirstTime
            }
            if (lastUpdate + 1000 * 3600 < (new Date()).getTime()) {
                return LocationAsk.Update
            }
        }
        return false
    }
)

export const selectCoords = createSelector(
    state => state.location.coords?.lat,
    state => state.location.coords?.lng,
    (lat, lng) => lat && lng ? ({ lat, lng }) : null
)

export const selectDisabled = createSelector(
    state => state.location.disabled ? true : false,
    disabled => disabled
)