import { createSelector, createSlice } from '@reduxjs/toolkit'

const { reducer, actions } = createSlice({
    name: 'browse',
    initialState: {
        history: {},
        lastSearch: null,
        nearbyRedir: false
    },
    reducers: {
        addFilters: {
            prepare(filters) {
                return {
                    payload: {
                        filterId: JSON.stringify(filters),
                        filters
                    }
                }
            },
            reducer(state, { payload: { filterId, filters } }) {
                if (!state.history[filterId]) {
                    state.history[filterId] = {
                        filters,
                        createdAt: (new Date()).toISOString(),
                        usage: 0,
                        views: 0
                    }
                }
                state.history[filterId].usage++
                state.history[filterId].lastUsageAt = (new Date()).toISOString()
            }
        },
        setLastSearch(state, { payload }) {
            state.lastSearch = payload
        },
        setNearbyRedirect: {
            prepare(bool) {
                return {
                    payload: Boolean(bool)
                }
            },
            reducer(state, { payload }) {
                state.nearbyRedir = payload
            }
        },
        viewProfile: {
            prepare(filters) {
                return {
                    payload: {
                        filterId: JSON.stringify(filters)
                    }
                }
            },
            reducer(state, { payload: { filterId } }) {
                if (state.history[filterId]) {
                    state.history[filterId].views++
                }
            }
        }
    }
})

export default reducer

export const { addFilters, viewProfile, setLastSearch, setNearbyRedirect } = actions

export const selectHistoryLength = createSelector(
    state => state.browse.history,
    (history) => Object.keys(history).length
)