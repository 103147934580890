import { combineReducers, configureStore } from '@reduxjs/toolkit'
import persistStore from 'redux-localstorage'
import app from './app'
import user from './user'
import favorites from './favorites'
import browse from './browse'
import location from './location'
import collect from './collect'

const reducers = combineReducers({
    app,
    user,
    favorites,
    browse,
    location,
    collect
})

export default configureStore({
    reducer(state, action) {
        if (action.type === 'USER_LOGOUT') {
            state = undefined
        }
        return reducers(state, action)
    },
    enhancers: [
        persistStore([
            "favorites",
            "location"
        ], {
            key: 'nejnostnet'
        })
    ]
})