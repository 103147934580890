import React from 'react'

import { useDispatch } from 'react-redux'

import firebase from '../firebase'
import { useFavoritesSync } from '../hook/useFavorites'
import { setUser } from '../redux/user'
import { useCollectSend } from '../hook/useCollect'
import { setPwaInstall, setPwaPrompt } from '../redux/app'
import { useAppUpdate } from '../hook/useAppUpdate'

export default function Updates() {

    const dispatch = useDispatch()

    React.useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(fbUser => {
            dispatch(setUser(fbUser))
            firebase.analytics().setUserId(fbUser?.uid)
        })

        function beforeInstallPrompt(event) {
            event.preventDefault()
            dispatch(setPwaPrompt(event))
        }

        function appInstalled() {
            dispatch(setPwaInstall(true))
            firebase.analytics().logEvent('app_install', {
                state: 'installed'
            })
        }

        window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
        window.addEventListener('appinstalled', appInstalled);

        return () => {
            unsubscribe()
            window.removeEventListener('beforeinstallprompt', beforeInstallPrompt);
            window.removeEventListener('appinstalled', appInstalled);
        }
    }, [dispatch])

    useFavoritesSync()

    useCollectSend()

    useAppUpdate()
    
    return null
}