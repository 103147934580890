import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

firebase.initializeApp({
    apiKey: "AIzaSyAZdx6vVkSP_oS59ylwz-LkVq4fAs_GKPA",
    authDomain: "nejnost-2a9a6.firebaseapp.com",
    databaseURL: "https://nejnost-2a9a6.firebaseio.com",
    projectId: "nejnost-2a9a6",
    storageBucket: "nejnost-2a9a6.appspot.com",
    messagingSenderId: "375552124823",
    appId: "1:375552124823:web:17cc1aa062fcfa8e4e86d2",
    measurementId: "G-R1SPXQPGYQ"
})

firebase.auth().languageCode = 'bg'

export default firebase